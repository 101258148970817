import React from "react";
import { Layout } from "antd";
import NavBar from "./navbar";
import Sidebar from "./sidebar";
import PropTypes from "prop-types";
import Footer from "./footer";

const MainLayout = ({ children }) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout>
        <NavBar />
        <Layout style={{ padding: 20, paddingTop: 10 }}>{children}</Layout>
        <Footer />
      </Layout>
    </Layout>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(MainLayout);
