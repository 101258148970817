import React from "react";
import {
  BranchesOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  AppstoreOutlined,
  CompassOutlined,
  DollarOutlined,
  PieChartOutlined,
  SyncOutlined,
  UserOutlined,
  CoffeeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import JTExpress from "./external-delivery-imgs/JTExpress.png";
import pExpress from "./external-delivery-imgs/pExpress.png";
import ninetyNineMins from "./external-delivery-imgs/99minutos.png";
import fedex from "./external-delivery-imgs/fedexLogo.avif";
import ups from "./external-delivery-imgs/ups-logo.jpg";
import redpack from "./external-delivery-imgs/redpack-logo.jpg";
import Estafeta from "./external-delivery-imgs/estafeta.png";
import dhl from "./external-delivery-imgs/dhl-logo.png";

const frontEndCurrentVersion = "1.2.0";
const mobileBreakpoint = 1000;
const dateFormat = "DD/MM/YYYY";
const timeFormat = "HH:mm";
const datetimeFormat = `${dateFormat}`;
const currencies = Object.freeze({
  mxn: "mxn",
  usd: "usd",
});
const defaultParams = {
  page: 1,
  pageSize: 10,
};
const mongoModels = Object.freeze({
  User: "User",
  Business: "Business",
  Location: "Location",
  Vehicle: "Vehicle",
  Service: "Service",
  Parcel: "Parcel",
  Permission: "Permission",
  CoverageArea: "CoverageArea",
  Route: "Route",
  Deposit: "Deposit",
  Store: "Store",
  PartialParcel: "PartialParcel",
  IntegrationParcel: "IntegrationParcel",
});
const mongoOperations = Object.freeze({
  insert: "insert",
  delete: "delete",
  replace: "replace",
  update: "update",
});
const views = Object.freeze({
  information: {
    title: "Información",
    icon: <InfoCircleOutlined />,
    dropinFulfillment: false,
    value: "information",
  },
  dashboard: {
    title: "Dashboard",
    icon: <InfoCircleOutlined />,
    dropinFulfillment: false,
    value: "dashboard",
  },
  services: {
    title: "Tus Guías",
    icon: <BranchesOutlined />,
    dropinFulfillment: false,
    value: "services",
  },
  dropinFulfillment: {
    title: "Fulfillment",
    icon: <CoffeeOutlined />,
    dropinFulfillment: true,
    value: "dropinFulfillment",
  },
  locations: {
    title: "Bodegas",
    icon: <CompassOutlined />,
    dropinFulfillment: false,
    value: "locations",
  },
  deposits: {
    title: "Depósitos",
    icon: <DollarOutlined />,
    dropinFulfillment: false,
    value: "deposits",
  },
  analytics: {
    title: "Analísis",
    icon: <PieChartOutlined />,
    dropinFulfillment: false,
    value: "analytics",
  },
  users: {
    title: "Mi Negocio",
    dropinFulfillment: false,
    hasSubmenu: true,
    icon: <UserOutlined />,
    submenu: {
      profile: {
        title: "Perfil",
        icon: null,
        value: "profile",
        dropinFulfillment: false,
      },
      products: {
        title: "Productos",
        icon: null,
        value: "products",
        dropinFulfillment: false,
      },
      users: {
        title: "Usuarios",
        icon: null,
        value: "users",
        dropinFulfillment: false,
      },
    },
  },
  quoter: {
    title: "Cotizador",
    icon: <DollarOutlined />,
    dropinFulfillment: false,
    value: "quoter",
  },
  marketplace: {
    title: "MarketPlace",
    icon: <AppstoreOutlined />,
    dropinFulfillment: false,
    value: "marketplace",
  },
});

const permissionOperations = Object.freeze({
  create: { title: "Crear" },
  read: { title: "Leer" },
  update: { title: "Editar" },
  delete: { title: "Eliminar" },
});
const serviceStatus = Object.freeze({
  created: {
    name: "Creado",
    value: "created",
    color: "#fadb14",
    icon: <ClockCircleOutlined />,
  },
  queued: {
    name: "En Camino",
    value: "queued",
    color: "#faad14",
    icon: <SyncOutlined spin />,
  },
  cancelled: {
    name: "Cancelado",
    value: "cancelled",
    color: "#f5222d",
    icon: <CloseCircleOutlined />,
  },
  en_route: {
    name: "En ruta",
    value: "en_route",
    color: "#52b266",
    icon: <SyncOutlined spin />,
  },
  completed: {
    name: "Completado",
    value: "completed",
    color: "#2f54eb",
    icon: <CheckCircleOutlined />,
  },
  partially_completed: {
    name: "Entregando",
    value: "partially_completed",
    color: "#597ef7",
    icon: <SyncOutlined spin />,
  },
});

const routeStatus = Object.freeze({
  created: { name: "Creado", value: "created", color: "#fadb14" },
  in_progress: { name: "En progreso", value: "in_progress", color: "#52b266" },
  cancelled: { name: "Cancelado", value: "cancelled", color: "#f5222d" },
  completed: { name: "Completado", value: "completed", color: "#2f54eb" },
});

const parcelStatus = Object.freeze({
  created: {
    name: "Creado",
    value: "created",
    color: "#fadb14",
    text: "Recolectando",
  },
  returned: {
    name: "Regresado",
    value: "returned",
    color: "#f5222d",
    text: "Cancelado",
  },
  in_validation: {
    name: "En Validacion",
    value: "in_validation",
    color: "#ff9900",
  },
  returning: {
    name: "Regresando",
    value: "returning",
    color: "#f5228d",
    text: "Intento de Entrega",
  },
  en_route: {
    name: "En ruta",
    value: "en_route",
    color: "#52b266",
    text: "En Camino",
  },
  in_depot: {
    name: "En Bodega",
    value: "in_depot",
    color: "#70D6FF",
    text: "En Bodega",
  },
  standBy: {
    name: "En Espera",
    value: "standBy",
    color: "#7E7E7E",
    text: "Error de Tienda",
  },
  delivered: {
    name: "Completado",
    value: "delivered",
    color: "#2f54eb",
    text: "Entregado",
  },
  cancelled: {
    name: "Cancelado",
    value: "cancelled",
    color: "red",
    text: "Cancelado",
  },
});

const integrationStatus = Object.freeze({
  created: {
    name: "Creado",
    value: "created",
    color: "#fadb14",
    text: "Recolectando",
  },
  generated: {
    name: "Generado",
    value: "generated",
    color: "#2f54eb",
    text: "Generado",
  },
  cancelled: {
    name: "Cancelado",
    value: "cancelled",
    color: "red",
    text: "Cancelado",
  },
});

const geoJSONType = Object.freeze({
  Point: "Point",
  LineString: "LineString",
  Polygon: "Polygon",
  Multipoint: "Multipoint",
  MultiLineString: "MultiLineString",
  MultiPolygon: "MultiPolygon",
});
const vehicleTypes = Object.freeze({
  motorcycle: "motorcycle",
  pickup: "pickup",
  minivan: "minivan",
  light_truck: "light_truck",
  light_truck_350: "light_truck_350",
  light_truck_b: "light_truck_b",
  light_truck_c: "light_truck_c",
});
const folderPaths = {
  profileImgs: "misc/users",
};
const parcelSizes = Object.freeze({
  xsmall: {
    value: "xsmall",
    volumetricWeight: { min: 0, max: 1.99 },
    price: 10,
  },
  small: { value: "small", volumetricWeight: { min: 2, max: 2.99 }, price: 12 },
  medium: {
    value: "medium",
    volumetricWeight: { min: 3, max: 4.99 },
    price: 14,
  },
  large: { value: "large", volumetricWeight: { min: 5, max: 9.99 }, price: 16 },
  xlarge: {
    value: "xlarge",
    volumetricWeight: { min: 10, max: 25 },
    price: 20,
  },
});
const parcelTierTypes = Object.freeze({
  0: {
    value: 0,
    id: "Local",
    name: "Local",
    description: "Entregas Mismo Día",
    rate: 1.2,
  },
  1: {
    value: 1,
    id: "Extended",
    name: "Extendida",
    description: "Entregas Mismo Día o Siguiente Día",
    rate: 1.1,
  },
  2: {
    value: 2,
    id: "National",
    name: "Nacional",
    description: "Envios nacionales con paqueterias aliadas.",
    rate: 1.0,
  },
});
const depositTypes = {
  internal: "internal",
  external: "external",
};

const phoneCountryCodes = Object.freeze({
  MX: { value: "MX", countryCode: "52" },
  US: { value: "US", countryCode: "1" },
});

const parcelErrors = {
  firstName: 0,
  lastName: 1,
  email: 2,
  phone: 3,
  reference: 4,
  value: 5,
  weight: 6,
  tier: 7,
  depth: 8,
  width: 9,
  height: 10,
  destination: 11,
  coverageArea: 12,
};
const payOnDeliveryStatus = Object.freeze({
  fetching_payment: {
    name: "Adquiriendo pago",
    value: "fetching_payment",
    color: "#fadb14",
  },
  pending_driver_deposit: {
    name: "Deposito ",
    value: "pending_driver_deposit",
    color: "#f5228d",
  },
  pending_payment_validation: {
    name: "Validación pendiente",
    value: "pending_payment_validation",
    color: "#52b266",
  },
  pending_transaction: {
    name: "Transacción pendiente",
    value: "pending_transaction",
    color: "#70D6FF",
  },
  transaction_complete: {
    name: "Transacción completa",
    value: "transaction_complete",
    color: "#2f54eb",
  },
});

const userTypes = Object.freeze({
  super: "super",
  admin: "admin",
  client: "client",
  driver: "driver",
  depot: "depot",
});
const devSecretKey =
  "53d81981a5d155bc2766c3d77a665a4964dbdc78db106cec2f937dc51eda662e";
const dropinContact = "+528111276967"; // Mariano Montañez
const trackingDropinContact = "+5218129391869"; // Tellpehant Public
const bankAccount = "Cuenta de Cheques";
const dropInBankAccountData = Object.freeze({
  clabe: "012580001180015613",
  accountNumber: "0118001561",
});
const emailRegEx = /\S+@\S+\.\S+/;
const businessTypes = Object.freeze({
  permanent_a: {
    value: "permanent_a",
    quantity: "800+",
  },
  permanent_b: {
    value: "permanent_b",
    quantity: "201-800",
  },
  permanent_c: {
    value: "permanent_c",
    quantity: "0-200",
  },
});

const businessPrices = Object.freeze({
  aaa: { value: "aaa", prices: { Estándar: 6380, Express: 6380 } },
  aa: { value: "aa", prices: { Estándar: 6380, Express: 6380 } },

  permanent_a: {
    value: "permanent_a",
    prices: { Estándar: 6380, Express: 6380 },
  },
  permanent_b: {
    value: "permanent_b",
    prices: { Estándar: 6960, Express: 6960 },
  },
  permanent_c: {
    value: "permanent_c",
    prices: { Estándar: 7540, Express: 7540 },
  },
});

const externalDelivery = Object.freeze([
  {
    name: "Terrestre",
    id: "jtExpress",
    img: JTExpress,
    fixedPrice: 52,
    priority: "3-7 Días",
    weightMax: 2,
    extraWeight: 12,
    isWeShip: false,
  },
  {
    name: "Estándar",
    id: "pqtExpress",
    img: pExpress,
    fixedPrice: 139,
    priority: "3-5 Días",
    weightMax: 1,
    extraWeight: 9,
    isWeShip: false,
  },
  {
    name: "Next Day",
    id: "pqtExpressND",
    img: pExpress,
    fixedPrice: 139,
    priority: "1-2 Días",
    weightMax: 1,
    extraWeight: 9,
    isWeShip: false,
  },
  {
    name: "SameDay",
    id: "99minutesSMD",
    img: ninetyNineMins,
    fixedPrice: 101,
    priority: "3-7 Días",
    weightMax: 1,
    extraWeight: 9,
    isWeShip: false,
  },
  {
    name: "NextDay",
    id: "99minutesNXD",
    img: ninetyNineMins,
    fixedPrice: 109,
    priority: "7-10 Días",
    weightMax: 1,
    extraWeight: 9,
    isWeShip: false,
  },
  {
    name: "Estafeta",
    id: "60",
    priority: "Dia Sig.",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: Estafeta,
    isWeShip: true,
  },
  {
    name: "Estafeta",
    id: "70",
    priority: "Terrestre",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: Estafeta,
    isWeShip: true,
  },
  {
    name: "Estafeta",
    id: "72",
    priority: "Terrestre SR",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: Estafeta,
    isWeShip: true,
  },
  {
    name: "DHL",
    id: "dhl_1",
    priority: "EXPRESS DOMESTIC 12:00",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: dhl,
    isWeShip: true,
  },
  {
    name: "DHL",
    id: "dhl_8",
    priority: "EXPRESS EASY NONDOC",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: dhl,
    isWeShip: true,
  },
  {
    name: "DHL",
    id: "dhl_G",
    priority: "ECONOMY SELECT DOMESTIC",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: dhl,
    isWeShip: true,
  },
  {
    name: "DHL",
    id: "dhl_N",
    priority: "EXPRESS DOMESTIC",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: dhl,
    isWeShip: true,
  },
  {
    name: "DHL",
    id: "dhl_O",
    priority: "EXPRESS DOMESTIC 10:30",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: dhl,
    isWeShip: true,
  },
  {
    name: "Redpack",
    id: "ECOEXPRESS",
    priority: "ECOEXPRESS",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: redpack,
    isWeShip: true,
  },
  {
    name: "Redpack",
    id: "EXPRESS",
    priority: "EXPRESS",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: redpack,
    isWeShip: true,
  },
  {
    name: "Fedex",
    id: "FEDEX_EXPRESS_SAVER",
    priority: "EXPRESS SAVER",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: fedex,
    isWeShip: true,
  },
  {
    name: "Fedex",
    id: "FEDEX_INTERNATIONAL_PRIORITY",
    priority: "FedEx International Priority",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: fedex,
    isWeShip: true,
  },
  {
    name: "Fedex",
    id: "FIRST_OVERNIGHT",
    priority: "FIRST OVERNIGHT",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: fedex,
    isWeShip: true,
  },
  {
    name: "Fedex",
    id: "INTERNATIONAL_ECONOMY",
    priority: "INTERNATIONAL ECONOMY",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: fedex,
    isWeShip: true,
  },
  {
    name: "Fedex",
    id: "INTERNATIONAL_PRIORITY",
    priority: "INTERNATIONAL PRIORITY",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: fedex,
    isWeShip: true,
  },
  {
    name: "Estafeta",
    id: "ltl",
    priority: "LTL",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: Estafeta,
    isWeShip: true,
  },
  {
    name: "Fedex",
    id: "PRIORITY_OVERNIGHT",
    priority: "PRIORITY OVERNIGHT",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: fedex,
    isWeShip: true,
  },
  {
    name: "UPS",
    id: "Saver",
    priority: "Saver",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: ups,
    isWeShip: true,
  },
  {
    name: "Fedex",
    id: "STANDARD_OVERNIGHT",
    priority: "STANDARD OVERNIGHT",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: fedex,
    isWeShip: true,
  },
  {
    name: "Estafeta",
    id: "T0",
    priority: "Metropoli",
    fixedPrice: 100,
    weightMax: 1,
    extraWeight: 9,
    img: Estafeta,
    isWeShip: true,
  },
]);

const deliveryCompanyWebsites = Object.freeze({
  estafeta: "https://www.estafeta.com/",
  jtExpress: "https://www.jtexpress.mx/",
  pqtExpress: "https://www.paquetexpress.com.mx/",
});

const parcelLimits = Object.freeze({
  weight: {
    value: 15,
    error: `El peso máximo es de ${15} kg`,
  },
  height: {
    value: 60,
    error: `La altura maxima es de ${65} centímetros`,
  },
  width: {
    value: 60,
    error: `La anchura maxima es de ${65} centímetros`,
  },
  depth: {
    value: 60,
    error: `La profunidad maxima es de ${65} centímetros`,
  },
  pce: {
    value: 6000,
    error: `El monto maximo es de ${6000} pesos`,
  },
});

const standByReasons = Object.freeze({
  parcelsNotReady: "Los paquetes no están listos",
  verifyAddress: "La dirección no ha sido verificada",
});

const mappedMonths = Object.freeze({
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
});

const postCodeRegex = new RegExp(/($|\s)\d{5}($|\s)/);
const oneDigitRegex = new RegExp(/.*[0-9].*/);

const depositStatus = Object.freeze({
  pendingValidation: {
    value: "pendingValidation",
    name: "Pendiente",
    color: "blue",
    description:
      "Su pago esta siendo validado por el proveedor transaccional. En cuanto se apruebe se le abonará su saldo de forma automática.",
  },
  confirmed: {
    value: "confirmed",
    name: "Confirmado",
    color: "green",
    description: "¡Su pago fue aprobado exitosamente!",
  },
  rejected: {
    value: "rejected",
    name: "Rechazado",
    color: "red",
    description:
      "Su pago fue rechazado. Si tiene incertidumbres no dude en contactarse con nuestro equipo de soporte.",
  },
});

const parcialParcelStatus = Object.freeze({
  unfulfilled: { name: "Unfulfilled", value: "unfulfilled", color: "#fadb14" },
  fulfilled: { name: "Fulfilled", value: "fulfilled", color: "#52b266" },
  cancelled: { name: "Regresando", value: "cancelled", color: "#f5222d" },
  standBy: { name: "En Espera", value: "standBy", color: "#7E7E7E" },
  delivered: { name: "Generado", value: "delivered", color: "#2f54eb" },
});

const parcelDestinationStates = [
  {
    name: "Aguascalientes",
    code_2_digits: "AG",
    code_3_digits: "AGS",
    code_shopify: "AGS",
    country_code: "MX",
  },
  {
    name: "Baja California",
    code_2_digits: "BC",
    code_3_digits: "BC",
    code_shopify: "BC",
    country_code: "MX",
  },
  {
    name: "Baja California Sur",
    code_2_digits: "BS",
    code_3_digits: "BCS",
    code_shopify: "BCS",
    country_code: "MX",
  },
  {
    name: "Campeche",
    code_2_digits: "CM",
    code_3_digits: "CAMP",
    code_shopify: "CAMP",
    country_code: "MX",
  },
  {
    name: "Chiapas",
    code_2_digits: "CS",
    code_3_digits: "CHIS",
    code_shopify: "CHIS",
    country_code: "MX",
  },
  {
    name: "Chihuahua",
    code_2_digits: "CH",
    code_3_digits: "CHIH",
    code_shopify: "CHIH",
    country_code: "MX",
  },
  {
    name: "Ciudad de México",
    code_2_digits: "DF",
    code_3_digits: "DF",
    code_shopify: "DF",
    country_code: "MX",
  },
  {
    name: "Coahuila",
    code_2_digits: "CO",
    code_3_digits: "COAH",
    code_shopify: "COAH",
    country_code: "MX",
  },
  {
    name: "Colima",
    code_2_digits: "CL",
    code_3_digits: "COL",
    code_shopify: "COL",
    country_code: "MX",
  },
  {
    name: "Durango",
    code_2_digits: "DG",
    code_3_digits: "DGO",
    code_shopify: "DGO",
    country_code: "MX",
  },
  {
    name: "Guanajuato",
    code_2_digits: "GT",
    code_3_digits: "GTO",
    code_shopify: "GTO",
    country_code: "MX",
  },
  {
    name: "Guerrero",
    code_2_digits: "GR",
    code_3_digits: "GRO",
    code_shopify: "GRO",
    country_code: "MX",
  },
  {
    name: "Hidalgo",
    code_2_digits: "HG",
    code_3_digits: "HGO",
    code_shopify: "HGO",
    country_code: "MX",
  },
  {
    name: "Jalisco",
    code_2_digits: "JA",
    code_3_digits: "JAL",
    code_shopify: "JAL",
    country_code: "MX",
  },
  {
    name: "México",
    code_2_digits: "MX",
    code_3_digits: "MEX",
    code_shopify: "MEX",
    country_code: "MX",
  },
  {
    name: "Michoacán",
    code_2_digits: "MI",
    code_3_digits: "MICH",
    code_shopify: "MICH",
    country_code: "MX",
  },
  {
    name: "Morelos",
    code_2_digits: "MO",
    code_3_digits: "MOR",
    code_shopify: "MOR",
    country_code: "MX",
  },
  {
    name: "Nayarit",
    code_2_digits: "NA",
    code_3_digits: "NAY",
    code_shopify: "NAY",
    country_code: "MX",
  },
  {
    name: "Nuevo León",
    code_2_digits: "NL",
    code_3_digits: "NL",
    code_shopify: "NL",
    country_code: "MX",
  },
  {
    name: "Oaxaca",
    code_2_digits: "OA",
    code_3_digits: "OAX",
    code_shopify: "OAX",
    country_code: "MX",
  },
  {
    name: "Puebla",
    code_2_digits: "PU",
    code_3_digits: "PUE",
    code_shopify: "PUE",
    country_code: "MX",
  },
  {
    name: "Querétaro",
    code_2_digits: "QT",
    code_3_digits: "QRO",
    code_shopify: "QRO",
    country_code: "MX",
  },
  {
    name: "Quintana Roo",
    code_2_digits: "QR",
    code_3_digits: "Q ROO",
    code_shopify: "Q ROO",
    country_code: "MX",
  },
  {
    name: "San Luis Potosí",
    code_2_digits: "SL",
    code_3_digits: "SLP",
    code_shopify: "SLP",
    country_code: "MX",
  },
  {
    name: "Sinaloa",
    code_2_digits: "SI",
    code_3_digits: "SIN",
    code_shopify: "SIN",
    country_code: "MX",
  },
  {
    name: "Sonora",
    code_2_digits: "SO",
    code_3_digits: "SON",
    code_shopify: "SON",
    country_code: "MX",
  },
  {
    name: "Tabasco",
    code_2_digits: "TB",
    code_3_digits: "TAB",
    code_shopify: "TAB",
    country_code: "MX",
  },
  {
    name: "Tamaulipas",
    code_2_digits: "TM",
    code_3_digits: "TAMPS",
    code_shopify: "TAMPS",
    country_code: "MX",
  },
  {
    name: "Tlaxcala",
    code_2_digits: "TL",
    code_3_digits: "TLAX",
    code_shopify: "TLAX",
    country_code: "MX",
  },
  {
    name: "Veracruz",
    code_2_digits: "VE",
    code_3_digits: "VER",
    code_shopify: "VER",
    country_code: "MX",
  },
  {
    name: "Yucatán",
    code_2_digits: "YU",
    code_3_digits: "YUC",
    code_shopify: "YUC",
    country_code: "MX",
  },
  {
    name: "Zacatecas",
    code_2_digits: "ZA",
    code_3_digits: "ZAC",
    code_shopify: "ZAC",
    country_code: "MX",
  },
];

export {
  mobileBreakpoint,
  depositStatus,
  dateFormat,
  timeFormat,
  datetimeFormat,
  currencies,
  defaultParams,
  mongoOperations,
  mongoModels,
  views,
  permissionOperations,
  serviceStatus,
  parcelStatus,
  geoJSONType,
  vehicleTypes,
  folderPaths,
  parcelSizes,
  parcelTierTypes,
  depositTypes,
  phoneCountryCodes,
  parcelErrors,
  payOnDeliveryStatus,
  userTypes,
  devSecretKey,
  dropinContact,
  bankAccount,
  dropInBankAccountData,
  emailRegEx,
  businessTypes,
  businessPrices,
  externalDelivery,
  parcelLimits,
  standByReasons,
  frontEndCurrentVersion,
  mappedMonths,
  deliveryCompanyWebsites,
  postCodeRegex,
  oneDigitRegex,
  trackingDropinContact,
  parcialParcelStatus,
  integrationStatus,
  parcelDestinationStates,
  routeStatus,
};
