import styled from "styled-components";
import { Layout, Button } from "antd";

const { Footer } = Layout;

const FooterContainer = styled(Footer)`
  text-align: center;
  padding: 5px;
  margin-top: 5px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
`;

const FixedButton = styled(Button)`
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0px 20px 20px 0px;
`;

export { FooterContainer, Column, ButtonsRow, FixedButton };
