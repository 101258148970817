import { useQuery, useSubscription } from "@apollo/client";
import { REQUEST_UPDATES } from "./requests";
import { useState } from "react";
import { defaultParams, mongoOperations } from "utils/constants";

const generateFields = (fields) => {
  return Object.entries(fields).reduce((obj, [key, value]) => {
    obj[key] = () => value;
    return obj;
  }, {});
};

const generatePaginationInfo = (count, page, pageSize) => {
  const pages = Math.ceil(count / pageSize);
  const prev = page > 1 ? page - 1 : null;
  const next = page < pages ? page + 1 : null;

  return { count, pages, prev, next };
};

const movePage = (page, setPage) => {
  if (page) setPage(page);
};

/*
 Currently this implementation only works with queries of type: { info: {...}, results: {...} }. In other words,
 plural queries. Ideally this implementation should be generalized to individual fetching items. Always request
 the entire info: { count, pages, next, prev } object!!!
 TODO: Refactor to support singular-type queries.
 TODO: Default info object if not included in query.
*/
/**
 *
 * @param subscriptionPayload {Object} - Pass an argument of the form { model: mongoModels, namespace: string }
 * @param queries {Object} - Pass an argument of the form { mainQuery: GQL, populateQuery: GQL }
 * @param variables {Object} - Object containing main query arguments.
 * @returns
 */
const useQueryRt = (
  subscriptionPayload,
  queries,
  variables = { params: defaultParams }
) => {
  const { mainQuery, populateQuery } = queries;
  const [page, setPage] = useState(1);
  const variablesToSet = {
    ...variables,
    params: {
      ...(variables.params ?? defaultParams),
      page,
    },
  };
  const [mainQueryName, populateQueryName] = [
    mainQuery.definitions[1].name.value,
    populateQuery.definitions[1].name.value,
  ];

  const { data, error, loading, refetch } = useQuery(mainQuery, {
    variables: variablesToSet,
  });

  if (error) {
    return;
  }

  useSubscription(REQUEST_UPDATES, {
    shouldResubscribe: true,
    variables: { ...subscriptionPayload },
    onSubscriptionData: ({
      client,
      subscriptionData: {
        data: { updates },
      },
    }) => {
      (async () => {
        if (updates) {
          const { operationType, fullDocument } = updates;
          const { data: populateQueryData } = await client.query({
            query: populateQuery,
            variables: { id: fullDocument.id },
            fetchPolicy: "network-only",
          });
          const { [populateQueryName]: documentData } = populateQueryData;

          const id = client.cache.identify({
            id: documentData.id,
            __typename: subscriptionPayload.model,
          });
          const data = client.readQuery({
            query: mainQuery,
            variables: variablesToSet,
          });

          if (!data) return;

          switch (operationType) {
            case mongoOperations.update: {
              client.cache.modify({
                id: id ?? `${subscriptionPayload.model}:${documentData.id}`,
                fields: generateFields(documentData),
              });
              break;
            }
            case mongoOperations.insert: {
              const info = {
                ...data[mainQueryName]?.info,
                ...generatePaginationInfo(
                  data[mainQueryName]?.info.count + 1,
                  page,
                  variablesToSet.params.pageSize
                ),
              };
              client.writeQuery({
                query: mainQuery,
                variables: variablesToSet,
                data: {
                  ...data,
                  [mainQueryName]: {
                    info,
                    results: [
                      { ...documentData, id: documentData.id },
                      ...data[mainQueryName]?.results,
                    ],
                  },
                },
              });
              break;
            }
            case mongoOperations.delete: {
              const info = {
                ...data[mainQueryName]?.info,
                ...generatePaginationInfo(
                  data[mainQueryName]?.info.count - 1,
                  page,
                  variablesToSet.params.pageSize
                ),
              };
              client.cache.evict({ id });
              client.cache.gc();
              client.writeQuery({
                query: mainQuery,
                variables: variablesToSet,
                data: {
                  ...data,
                  [mainQueryName]: {
                    info,
                    results: [
                      ...data[mainQueryName]?.results.filter(
                        ({ id }) => id !== documentData.id
                      ),
                    ],
                  },
                },
              });
              break;
            }
          }
        }
      })();
    },
  });

  return {
    data,
    error,
    loading,
    // TODO: Unknown bug not displaying values on refetch when current page != 1.
    refetch: async (args = {}) => {
      return refetch({
        ...(variablesToSet ?? {}),
        ...args,
        /*
          Go back to page 1 everytime arguments change to avoid cache conflicts when
          fetching different datasets.
        */
        params: { ...variablesToSet.params, page: 1 },
      });
    },
    pageConfig: { ...variablesToSet.params },
    nextPage: () => movePage(data[mainQueryName]?.info.next, setPage),
    prevPage: () => movePage(data[mainQueryName]?.info.prev, setPage),
    toPage: (page) => movePage(page, setPage),
  };
};

export default useQueryRt;
