import React from "react";
import { Layout } from "antd";
import { AuthWrapper } from "./elements";
import PropTypes from "prop-types";

const AuthLayout = ({ children }) => {
  return (
    <Layout>
      <AuthWrapper>{children}</AuthWrapper>
    </Layout>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(AuthLayout);
