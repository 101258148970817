import axios from "axios";
import { backend } from "config/environment";

const client = axios.create({
  baseURL: backend.AUTH_URI,
  timeout: 100000,
  withCredentials: true,
});

const login = async (input) => {
  const { data } = await client.post("/auth/login", input);
  return data;
};

const register = async (input) => {
  const { data } = await client.post("auth/register", input);
  return data;
};

const checkPassword = async (input) => {
  const { data } = await client.get(
    `auth/checkPassword/${input.id}/${input.password}`
  );
  return data;
};

const updatePassword = async (input) => {
  const { data } = await client.put("/auth/updatePassword", input);
  return data;
};

export { login, register, checkPassword, updatePassword };
export default client;
