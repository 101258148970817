const tourConfig = [
  {
    // eslint-disable-next-line
    selector: `[data-tut="services_tab"]`,
    content: "Aquí podrás encontrar toda la información sobre tus servicios",
  },
  {
    // eslint-disable-next-line
    selector: `[data-tut="locations_tab"]`,
    content:
      "Antes de crear un servicio, registra la ubicación de origen del pedido." +
      " Podrás seleccionarla cada vez que necesites un servicio en esa ubicación.",
  },
  {
    // eslint-disable-next-line
    selector: `[data-tut="deposits_tab"]`,
    content:
      "¡Agrega saldo a tu cuenta! Para crear pedidos necesitarás recargar" +
      " el saldo que utilizarás.",
  },
  {
    // eslint-disable-next-line
    selector: `[data-tut="navbar"]`,
    content:
      "Este es tu saldo actual, podrás acceder a la pantalla de recarga desde aquí.",
  },
  {
    // eslint-disable-next-line
    selector: `[data-tut="services_view"]`,
    position: "top",
    content:
      "En esta pantalla podrás observar tu registro y estatus de tus Servicios y Paquetes." +
      " También podrás ver el historial de pagos realizados por DropIn con el servicio de pago contra entrega.",
  },
  {
    // eslint-disable-next-line
    selector: `[data-tut="create_service_btn"]`,
    content: "¡Listo! Haz click aquí para crear tu primer pedido.",
  },
];

export default tourConfig;
