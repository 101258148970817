import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import AuthLayout from "layouts/auth";

const Login = lazy(() => import("./login"));
const Onboarding = lazy(() => import("./onboarding"));

const Auth = () => {
  return (
    <AuthLayout>
      <Switch>
        <Suspense fallback={<TopBarProgress />}>
          <Route path="/login" component={Login} />
          <Route path="/onboarding" component={Onboarding} />
          <Redirect to="login" />
        </Suspense>
      </Switch>
    </AuthLayout>
  );
};

export default React.memo(Auth);
