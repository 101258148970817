/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useUser } from "providers/user";
import { views } from "utils/constants";
import { Menu, Sider } from "./elements";

const { SubMenu } = Menu;

const Sidebar = () => {
  const { push } = useHistory();
  const location = useLocation();
  const { user } = useUser();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const {
    business: { dropinFulfillment },
  } = user;

  return (
    <Sider
      theme="dark"
      breakpoint="lg"
      collapsedWidth="80"
      collapsible
      onCollapse={(collapsed) => setIsCollapsed(collapsed)}
    >
      <img
        src={`static/brand/logo_${isCollapsed ? "short" : "white"}.svg`}
        style={{ margin: "20px auto", display: "block" }}
        height="40em"
        alt="Dropin logo"
      />
      <Menu
        theme="dark"
        style={{ padding: 10 }}
        onClick={({ key }) => push(key)}
        mode="inline"
        selectedKeys={[location.pathname]}
      >
        {Object.entries(views).map(([key, view]) => {
          if (view.dropinFulfillment && !dropinFulfillment) return;
          if (view.hasSubmenu)
            return (
              <SubMenu key={key} icon={view.icon} title={view.title}>
                {Object.entries(view.submenu).map(([entry, view]) => {
                  return (
                    <Menu.Item key={entry}>
                      <Link
                        key={entry}
                        to={{
                          pathname: `/${entry}`,
                        }}
                      >
                        {view.title}
                      </Link>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
          return (
            <Menu.Item
              key={`/${view?.value}`}
              icon={view.icon}
              data-tut={`${view}_tab`}
            >
              {view.title}
            </Menu.Item>
          );
        })}
      </Menu>
    </Sider>
  );
};

export default React.memo(Sidebar);
