import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Typography, Layout, Row } from "antd";
const { Title, Text } = Typography;

const TutorialModal = ({ toggleIsTourOpen, isModalOpen, toggleModal }) => {
  return (
    <Modal
      visible={isModalOpen}
      cancelText={null}
      closable={false}
      footer={null}
      width="40%"
    >
      <Layout
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          flexWrap: "wrap",
          backgroundColor: "white",
        }}
      >
        <Row>
          <Title level={4}>¿Listo para crear tu primer servicio?</Title>
        </Row>
        <Row>
          <Text>
            Haz click en el botón para iniciar un tutorial de cómo crear un
            servicio paso por paso
          </Text>
        </Row>
        <Row style={{ justifyContent: "center" }}>
          <Button
            type="primary"
            style={{
              marginTop: 20,
            }}
            onClick={() => {
              toggleModal(false);
              toggleIsTourOpen(true);
            }}
          >
            Empezar
          </Button>
          <Button
            type="default"
            style={{
              marginTop: 20,
              marginLeft: 30,
            }}
            onClick={() => {
              toggleModal(false);
              localStorage.setItem("IntroApp_Disabled", true);
            }}
          >
            Omitir
          </Button>
        </Row>
      </Layout>
    </Modal>
  );
};

TutorialModal.propTypes = {
  isModalOpen: PropTypes.bool,
  toggleIsTourOpen: PropTypes.func,
  toggleModal: PropTypes.func,
};

export default TutorialModal;
