import styled, { keyframes } from "styled-components";

const HeaderKeyFrame = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

const AuthWrapper = styled.div`
  min-height: 100vh;

  background:linear-gradient(to right, #52B266,#029F78,#008A81);
  animation: ${HeaderKeyFrame} 10s ease infinite;
  background-size: 200% 100%;
  }
`;

export { AuthWrapper };
