import styled from "styled-components";
import { Layout, Menu as CommonMenu } from "antd";

const Menu = styled(CommonMenu)`
  .ant-menu-item-group-list .ant-menu-item {
    font-size: 11px;
  }

  .ant-menu-item-selected {
    border-radius: 30px;
  }
`;

const Sider = styled(Layout.Sider)``;

export { Menu, Sider };
