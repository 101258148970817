import { gql } from "@apollo/client";

const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      poweredUser
      firstName
      lastName
      region {
        id
      }
      email
      profileImg
      namespace
      business {
        id
        name
        businessName
        dropinFulfillment
        depot {
          id
        }
        configuration {
          showOriginInTracking
          pceConfiguration {
            name
            number
            bank
          }
          pricing
        }
        balance {
          amount
          exponent
          currency
        }
        fulfillment
        subBusinesses
        integrations {
          conekta {
            customerId
          }
        }
        apiKey {
          enabled
          secret
          application
          id
        }
        createdAt
        type
      }
      phones {
        countryCode
        number
      }
      permission {
        id
        name
        views
        operations {
          create
          read
          update
          delete
        }
      }
    }
  }
`;

export { GET_USER };
