import React from "react";
import { Modal, Typography, Button, Col, Row } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { WarningArea } from "./elements";

const VersionUpdateModal = ({ isModalOpen }) => {
  const updateClientBrowser = async () => {
    // We first delete all service workers and then cache in order to make sure that their requests aren't fetched from the cache.
    await navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    });
    await caches.keys().then((keyList) => {
      return Promise.all(
        keyList.map((key) => {
          return caches.delete(key);
        })
      );
    });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <Modal visible={isModalOpen} footer={null} closable={false}>
      <WarningArea>
        <WarningOutlined style={{ color: "red", fontSize: "70px" }} />
        <Row>
          <Col>
            <Typography.Title
              level={3}
              style={{ color: "red", textAlign: "center" }}
            >
              ¡Tu navegador esta utilizando una version desactualizada de
              DropIn!
            </Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              type="primary"
              onClick={async () => await updateClientBrowser()}
            >
              Presiona aqui para actualizar tu navegador.
            </Button>
          </Col>
        </Row>
      </WarningArea>
    </Modal>
  );
};

export default VersionUpdateModal;
