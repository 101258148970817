import React from "react";
import moment from "moment";
import { FooterContainer, Column, ButtonsRow, FixedButton } from "./elements";
import { Layout, Modal, Tooltip } from "antd";
import {
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { useUser } from "../../../providers/user/index";
import { dropinContact } from "utils/constants";
import { openWhatsapp } from "utils/functions";

const Footer = () => {
  const { confirm } = Modal;
  const { user } = useUser();

  function showConfirm() {
    confirm({
      title: "¿Deseas realizar los tutoriales de nuevo?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Haz click en el botón para iniciar un tutorial de cómo crear un" +
        "servicio paso por paso",
      onOk() {
        localStorage.removeItem("CreateLocation_Disabled");
        localStorage.removeItem("IntroApp_Disabled");
        localStorage.removeItem("CreateServiceTutorial_Disabled");
        location.reload();
      },
    });
  }

  return (
    <FooterContainer style={{ textAlign: "center" }}>
      <Layout
        style={{
          justifyContent: "space-between",
          flexDirection: "initial",
        }}
      >
        <Column></Column>
        <Column id="copyright" style={{ marginTop: "2rem" }}>
          {`TECNOLOGIAS DROPIN © ${moment().format("YYYY")}`}
        </Column>
        <ButtonsRow>
          <Tooltip placement="topRight" title="Tutorial">
            <FixedButton
              type="primary"
              shape="circle"
              size="large"
              style={{ margin: "0px 20px 20px 0px" }}
              onClick={showConfirm}
              icon={<QuestionCircleOutlined />}
            />
          </Tooltip>
          <Tooltip placement="topRight" title="Solicitar apoyo">
            <FixedButton
              type="primary"
              shape="circle"
              size="large"
              style={{ margin: "0px 80px 20px 0px" }}
              onClick={() =>
                openWhatsapp(
                  dropinContact,
                  `Hola, soy ${user.firstName} de ${user.business.name} y necesito asistencia.`
                )
              }
              icon={<WhatsAppOutlined />}
            />
          </Tooltip>
        </ButtonsRow>
      </Layout>
    </FooterContainer>
  );
};

export default Footer;
