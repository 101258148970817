import React, { createContext, useCallback, useEffect, useState } from "react";
import cookies from "react-cookies";
import jwt from "jsonwebtoken";
import { useLazyQuery } from "@apollo/client";
import { GET_USER } from "./requests";
import PropTypes from "prop-types";
import { message } from "antd";

const UserContext = createContext({});

const UserProvider = ({ children }) => {
  const [user, setUser] = useState("Loading");
  const [isLogged, setIsLogged] = useState(false);

  const [
    userQuery,
    { data: userData, error: userError, refetch: userRefetch },
  ] = useLazyQuery(GET_USER);

  const getUser = useCallback(() => {
    const token = cookies.load("token");
    const tokenPayload = token ? jwt.decode(token, {}) : undefined;

    if (tokenPayload != undefined) {
      userQuery({
        variables: {
          id: tokenPayload.id,
        },
      });

      if (userError) {
        message.error("Ha habido un error cargando el perfil!");
        setUser(null);
      }

      if (userData) {
        setUser(userData.user);
      }
    } else {
      setUser(null);
    }
  }, [isLogged, userData, userError, userQuery]);

  const logout = () => {
    cookies.remove("token");
    setUser(null);
    setIsLogged(false);
  };

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <UserContext.Provider
      value={{
        user,
        getUser,
        isLogged,
        setIsLogged,
        userRefetch,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserContext };
export default UserProvider;
