import { gql } from "@apollo/client";

const PARCEL_PRICE_ESTIMATE = gql`
  query parcelPriceEstimate(
    $values: ParcelPriceEstimate!
    $api: Boolean
    $serviceType: String
  ) {
    parcelPriceEstimate(values: $values, api: $api, serviceType: $serviceType) {
      price {
        amount
        exponent
        currency
      }
      type
    }
  }
`;

const GET_REGION_BY_NAME = gql`
  query regionByName($name: String!) {
    regionByName(name: $name) {
      id
    }
  }
`;

const GET_BACKEND_CONFIGURATION = gql`
  query {
    configurations {
      frontEndVersion
    }
  }
`;

const VALIDATE_RECOLLECTION_COORDINATES = gql`
  query validRecollectionCoordinates($point: GeoJSONInput!) {
    validRecollectionCoordinates(point: $point) {
      id
      extended
      region {
        id
      }
    }
  }
`;

export {
  PARCEL_PRICE_ESTIMATE,
  GET_REGION_BY_NAME,
  GET_BACKEND_CONFIGURATION,
  VALIDATE_RECOLLECTION_COORDINATES,
};
