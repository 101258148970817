import React from "react";
import { Modal, Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { WarningArea } from "./elements";

const MobileModal = () => {
  return (
    <Modal
      visible={true}
      style={{ margin: "0px 10px", height: "50%" }}
      footer={null}
      closable={false}
      bodyStyle={{ height: "250px" }}
    >
      <WarningArea>
        <WarningOutlined style={{ color: "dodgerBlue", fontSize: "70px" }} />
        <div>
          <Typography.Title
            level={3}
            style={{ color: "dodgerBlue", textAlign: "center" }}
          >
            Favor de ingresar en una Tablet o computadora.
          </Typography.Title>
        </div>
      </WarningArea>
    </Modal>
  );
};

export default MobileModal;
