import csvFileCreator from "csv-file-creator";
import {
  geoJSONType,
  parcelErrors,
  phoneCountryCodes,
  postCodeRegex,
} from "./constants";
import { copomex, env } from "config/environment";
import axios from "axios";
import moment from "moment";
import {
  GET_REGION_BY_NAME,
  PARCEL_PRICE_ESTIMATE,
  VALIDATE_RECOLLECTION_COORDINATES,
} from "./requests";
import client from "graph-ql";

Array.prototype.asyncForEach = function (callback) {
  const limit = this.length;
  const promise = new Promise((resolve) => {
    (async () => {
      for (let index = 0; index < limit; index++) {
        await callback(this[index], index, this);
      }
      resolve();
    })();
  });

  return promise;
};

const formatPhone = (phone, country) => {
  phone = String(phone).replace(/\s/g, "");
  if (country === phoneCountryCodes.MX.value) {
    const countryCode = phoneCountryCodes.MX.countryCode;
    return { countryCode, number: phone };
  }
  if (country === phoneCountryCodes.US.value) {
    const countryCode = phoneCountryCodes.US.countryCode;
    return { countryCode, number: phone };
  }
  if (!country && phone.length === 10) {
    const countryCode = phoneCountryCodes.MX.countryCode;
    return { countryCode, number: phone };
  }
  if (!country && phone.length <= 11) {
    return {
      error: "El teléfono del archivo es invalido.",
      type: parcelErrors.phone,
      input: phone,
    };
  }
  const number = phone.slice(phone.length - 10);
  const countryCode = phone.slice(0, phone.length - number.length);
  return { countryCode, number };
};

const isParcelOverWeightOrDimensionsLimit = (
  { weight, dimensions },
  weightLimit,
  dimensionLimit
) => {
  const weightCheck = weight > weightLimit;
  const dimensionsCheck = Object.values(dimensions).some(
    (dimension) => dimension > dimensionLimit
  );
  return weightCheck || dimensionsCheck;
};

const formatCurrency = (money) => {
  if (!money) return;
  const { amount, exponent, currency } = money;
  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency,
  }).format(amount / Math.pow(10, exponent));
};

const getCopomexGeoCordinatePostalCode = async (lng, lat) => {
  const url = `https://api.copomex.com/query/info_cp_geocoding_reverse?lat=${lat}&lng=${lng}&token=${copomex.key}`;
  try {
    const { data } = await axios.get(url);
    return data?.response;
  } catch (e) {
    console.log(e);
    return;
  }
};

const copomexPostalCode = async (lng, lat) => {
  try {
    const copomexGeoCoordinateData = await getCopomexGeoCordinatePostalCode(
      lng,
      lat
    );
    return copomexGeoCoordinateData;
  } catch (e) {
    console.log(e);
    return null;
  }
};

const formatAddress = async (location) => {
  const {
    address: [googleAddress],
    lng,
    lat,
    region,
    extended,
  } = location;
  if (!googleAddress["address_components"]) throw new Error("Invalid input!");
  const address = {};
  // Mapping between Dropin's address format and Google's address format.
  const addressComponentsMap = {
    country: "country",
    administrative_area_level_1: "state",
    locality: "city",
    sublocality: "colony",
    neighborhood: "neighborhood",
    route: "street",
    postal_code: "postcode",
  };

  for (const component of googleAddress["address_components"]) {
    for (const type of component.types) {
      if (addressComponentsMap[type]) {
        address[addressComponentsMap[type]] = component["long_name"];
      }
    }
  }

  address.colony = address?.neighborhood ?? address.colony;
  delete address?.neighborhood;

  if (!address.postcode || !address.city) {
    const copomexAddressData = await copomexPostalCode(lng, lat);
    address.postcode = address?.postcode ?? copomexAddressData?.cp;
    address.city = address?.city ?? copomexAddressData?.municipio;
  }
  return {
    destination: {
      ...address,
      fullName: location.result,
      location: {
        type: geoJSONType.Point,
        coordinates: [location.lng, location.lat],
      },
    },
    region,
    extended,
  };
};

const centroidOfPoints = (points) => {
  const centroid = { lat: 0, lng: 0 };
  if (points.length === 0)
    return {
      lat: 25.651434, // Default Lat for Tec de Monterrey
      lng: -100.2938946,
    };

  for (const { lat, lng } of points) {
    centroid.lat += lat;
    centroid.lng += lng;
  }
  centroid.lat /= points.length;
  centroid.lng /= points.length;
  return centroid;
};
//The for each function natively can't have both an input array and a callback, so we're making our own.
const asyncForEach = async (iterable, callback, ...args) => {
  try {
    for (let i = 0; i < iterable.length; ++i) {
      await callback(/*iterable=*/ iterable, /*index=*/ i, /*args=*/ args);
    }
  } catch (e) {
    console.log(e);
  }
};

const forceTimeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const printPdf = (base64PDF) =>
  new Promise((resolve) => {
    let byteCharacters;
    if (base64PDF.includes("base64"))
      byteCharacters = atob(base64PDF.split(",")[1]);
    else byteCharacters = base64PDF;

    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "application/pdf" });

    const urlBlob = URL.createObjectURL(blob);
    const iframe = document.getElementById("print");
    iframe.src = urlBlob;
    document.body.appendChild(iframe);

    iframe.onload = () => {
      setTimeout(() => {
        iframe.focus();
        iframe.contentWindow.print();
        resolve();
      }, 100);
    };
  });

const downloadParcelUploadTemplate = () => {
  let template = [
    [
      "Recipiente",
      " ",
      " ",
      " ",
      "Destino",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
      "Paquete",
      " ",
      " ",
      " ",
      " ",
      " ",
      " ",
      "Servicio",
      " ",
    ],
    [
      "Nombre",
      "Apellido",
      "Email",
      "Celular",
      "Pais",
      "Estado",
      "Ciudad",
      "Colonia",
      "Calle y numero",
      "Codigo postal",
      "Comentarios",
      "Referencia",
      "Valor pago con entrega",
      "Valor",
      "Peso (kg)",
      "Largo (cm)",
      "Ancho (cm)",
      "Alto (cm)",
    ],
  ];
  csvFileCreator("dropin-bulk-load.csv", template);
};

const parseAnalyticsData = (analyticsData) => {
  const parsedData = analyticsData.map(
    ({
      destination: {
        location: { coordinates },
      },
    }) => {
      return { lat: coordinates[1], lng: coordinates[0] };
    }
  );
  return parsedData;
};

const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

const formatRecolectionDate = (pickupDate, turn) => {
  let startPickupDate = pickupDate;

  startPickupDate.set("hour", turn === 9 ? 9 : 13);
  startPickupDate.set("minute", 0);
  startPickupDate.set("second", 0);

  let endPickupDate = new Date(moment(startPickupDate).toISOString());
  endPickupDate.setHours(turn === 9 ? 10 : 14);

  startPickupDate = moment(startPickupDate).toISOString();
  endPickupDate = moment(endPickupDate).toISOString();
  return { startPickupDate, endPickupDate };
};
const openWhatsapp = (contact, message) => {
  window.open(`https://wa.me/${contact}?text=${message}`, "_blank").focus();
};

const openAddressChange = (id) => {
  if (env.development) {
    window
      .open(`http://localhost:3000/addressChange?id=${id}`, "_blank")
      .focus();
  } else {
    window
      .open(`https://app.dropin.mx/addressChange?id=${id}`, "_blank")
      .focus();
  }
};

const showDateFormat = (value) => {
  if (!value) return;
  return value.locale("es").format("LL");
};

const dateIsAfter1 = (value) => {
  return value ? value.hour() >= 13 : null;
};

const swapElementsList = (list, item1, item2) => {
  if (!list || list.length === 0) return;
  let arr = [...list];
  [arr[item1], arr[item2]] = [arr[item2], arr[item1]];
  return arr;
};

const getAddressFullName = ({
  city,
  colony,
  country,
  postcode,
  state,
  street,
}) => {
  return `${street} ${colony} ${city} ${state} ${postcode} ${country}`;
};

const getNationalRegionId = async () => {
  const {
    data: {
      regionByName: { id },
    },
  } = await client.query({
    query: GET_REGION_BY_NAME,
    variables: {
      name: "National Deliveries",
    },
  });
  return id;
};

const calculateParcelPricing = async (
  { dimensions, weight, regions, extended, destination },
  nationalRegionId
) => {
  const { destination: region } = regions;
  const destinationFullName = getAddressFullName(destination);
  const price = await client.query({
    query: PARCEL_PRICE_ESTIMATE,
    variables: {
      api: true,
      values: {
        dimensions,
        destination: {
          ...destination,
          fullName: destinationFullName,
        },
        externalCarrier:
          String(region) === String(nationalRegionId) ? "jtExpress" : null,
        national: String(region) === String(nationalRegionId),
        extended: extended,
        weight,
      },
    },
    fetchPolicy: "cache-first",
  });
  return price;
};

const calculateLastSixMonths = (currentMonth) => {
  if (typeof currentMonth === "string" || currentMonth === 0) return [];

  var today = new Date();
  let returnMonths = [];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  for (var i = 5; i >= 0; i -= 1) {
    const d = new Date(today.getFullYear(), today.getMonth() - i, 1);
    returnMonths.push(months[d.getMonth()]);
  }

  return returnMonths;
};

const compareCommentsPostCode = (comments, destination) => {
  if (!comments || !destination) {
    return false;
  }
  // if potential postal code found
  if (postCodeRegex.test(comments)) {
    if (destination.postcode === postCodeRegex.exec(comments)[0].trim()) {
      return false;
    } else {
      return true;
    }
  }
  return false;
};

const replaceNewLine = (s) => {
  if (s === "") return s;

  let newValue = s.replace(/\n/g, " ");

  return newValue;
};

async function originHasRecollection(origin) {
  if (origin) {
    const {
      address: {
        location: {
          coordinates: [lng, lat],
        },
      },
    } = origin;

    const { data } = await client.query({
      query: VALIDATE_RECOLLECTION_COORDINATES,
      variables: {
        point: {
          type: geoJSONType.Point,
          coordinates: [lng, lat],
        },
      },
    });

    return data?.validRecollectionCoordinates !== null;
  }
}

const parcelExternalCarrier = (imageUrl) => {
  const lowerCaseImageUrl = imageUrl.toLowerCase();
  if (lowerCaseImageUrl.includes("estafeta")) {
    return "estafeta";
  } else if (lowerCaseImageUrl.includes("dhl")) {
    return "dhl";
  } else if (lowerCaseImageUrl.includes("redpack")) {
    return "redpack";
  } else if (lowerCaseImageUrl.includes("fedex")) {
    return "fedex";
  } else if (lowerCaseImageUrl.includes("jt")) {
    return "jtExpress";
  } else if (lowerCaseImageUrl.includes("pe")) {
    return "pqtExpress";
  }
};

export {
  formatPhone,
  formatCurrency,
  formatAddress,
  centroidOfPoints,
  asyncForEach,
  printPdf,
  downloadParcelUploadTemplate,
  forceTimeout,
  parseAnalyticsData,
  removeAccents,
  copomexPostalCode,
  formatRecolectionDate,
  openWhatsapp,
  openAddressChange,
  showDateFormat,
  dateIsAfter1,
  swapElementsList,
  getNationalRegionId,
  calculateParcelPricing,
  calculateLastSixMonths,
  compareCommentsPostCode,
  replaceNewLine,
  originHasRecollection,
  isParcelOverWeightOrDimensionsLimit,
  parcelExternalCarrier,
};
