import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "styled-components";
import client from "graph-ql";
import App from "./app";
import reportWebVitals from "./report-web-vitals";
import "antd/dist/antd.less";
import { UserProvider } from "providers/user";
import theme from "config/theme";
import { ConfigProvider } from "antd";
import "moment/locale/es";
import esES from "antd/lib/locale/es_ES";
import "./index.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://58c75aeaa41a4e4eb147a0078d2f7188@o1362031.ingest.sentry.io/4504330029432832",
  integrations: [new BrowserTracing()],
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
  debug: false,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <UserProvider>
        <Router basename="/">
          <ConfigProvider locale={esES}>
            <App />
          </ConfigProvider>
        </Router>
      </UserProvider>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
