/* eslint-disable no-unused-vars */
import React, { useEffect, useState, Suspense } from "react";
import { useUser } from "providers/user";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import MainLayout from "layouts/main";
import TopBarProgress from "react-topbar-progress-indicator";
import Loadable from "react-loadable";
import Auth from "views/auth";
import { BeatingContainer } from "./elements";
import Public from "./views/public";
import useQueryParam from "./hooks/use-query-param";
import client from "utils/auth";
import { Button, notification } from "antd";
import shortid from "shortid";
import cookie from "react-cookies";
import { CheckCircleOutlined } from "@ant-design/icons";
import TutorialModal from "./layouts/tutorial-modal";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import tourConfig from "utils/IntroAppTour";
import { views, frontEndCurrentVersion } from "utils/constants";
import MobileModal from "layouts/mobile-modal";
import VersionUpdateModal from "layouts/version-update";
import { GET_BACKEND_CONFIGURATION } from "utils/requests";
import graphClient from "graph-ql";
import TagManager from "react-gtm-module";

const App = () => {
  const { user } = useUser();

  const { push } = useHistory();
  const [[shop], [accessToken], [scopes]] = [
    useQueryParam("shop"),
    useQueryParam("accessToken"),
    useQueryParam("scopes"),
  ];
  //Tour config
  const [isTourOpen, toggleIsTourOpen] = useState(false);
  const [isTutorialModalOpen, toggleTutorialModal] = useState(false);
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  const [isClientBrowserUpToDate, setIsClientBrowserUpToDate] = useState(false);
  // Tag Manger Google
  const tagManagerArgs = {
    gtmId: "GTM-PWCGKZ8",
  };
  TagManager.initialize(tagManagerArgs);

  useEffect(() => {
    if (!localStorage.getItem("IntroApp_Disabled")) {
      toggleTutorialModal(true);
    }
  }, []);

  useEffect(async () => {
    try {
      if (!user) return;
      const {
        data: {
          configurations: { frontEndVersion },
        },
      } = await graphClient.query({
        query: GET_BACKEND_CONFIGURATION,
      });
      if (frontEndVersion === frontEndCurrentVersion) return;
      setIsClientBrowserUpToDate(true);
      return;
    } catch (e) {
      console.log(e);
    }
  }, [user]);

  /*
    We only react to Shopify oauth process when both `shopifyRedirectionCode` and `shopifyShop` query params
    are passed as a redirection strategy process done by Shopify. Receiving both parameters allows us to infer
    that the business started our Shopify app installation process from the /stores view.

    Once we can confirm the parameters are passed, we follow the next step: generate a permanent token and link
    it to the business. This way we can fetch data (such as orders) from the Shopify store.
  */
  useEffect(() => {
    (async () => {
      if (shop && accessToken && scopes) {
        const token = cookie.load("token");
        const payload = await client.post(
          "/shopify/register-store",
          {
            shop,
            accessToken,
            scopes,
          },
          { withCredentials: true, headers: { authorization: token } }
        );
        if (payload.data) {
          const notificationKey = shortid.generate();
          notification.success({
            message: "La conexión con Shopify fue exitosa",
            description:
              "Vamos a empezar a traer nuevas órdenes de tu tienda para que puedas automatizar tu proceso de recolección.",
            btn: (
              <Button
                size="small"
                onClick={() => notification.close(notificationKey)}
              >
                Entendido
              </Button>
            ),
            key: notificationKey,
            duration: 10,
          });
        }

        setTimeout(() => push("/stores"), 800);
      }
    })();
  }, []);

  if (user === "Loading")
    return (
      <BeatingContainer>
        <img src="/static/brand/logo_short_192.svg" alt="En construcción..." />
      </BeatingContainer>
    );

  if (
    location.pathname === "/tracking" ||
    location.pathname === "/addressChange"
  )
    return <Public />;
  if (!user) return <Auth />;

  return (
    <MainLayout>
      {screen.width < 500 && <MobileModal />}
      <VersionUpdateModal isModalOpen={isClientBrowserUpToDate} />
      <Suspense fallback={<TopBarProgress />}>
        <Switch>
          {Object.keys(views).map((key) => {
            if (key === "stores") return;
            return (
              <Route
                key={key}
                exact
                path={`/${key}`}
                component={Loadable({
                  loader: () => import(`views/${key}`),
                  loading: TopBarProgress,
                })}
              />
            );
          })}
          <Route
            key={"profile"}
            path={"/profile"}
            exact
            component={Loadable({
              loader: () => import("views/profile"),
              loading: TopBarProgress,
            })}
          />
          <Route
            key={"products"}
            path={"/products"}
            exact
            component={Loadable({
              loader: () => import("views/products"),
              loading: TopBarProgress,
            })}
          />
          <Route
            key={"settings"}
            path={"/settings"}
            exact
            component={Loadable({
              loader: () => import("views/settings"),
              loading: TopBarProgress,
            })}
          />
          <Route
            key={"development"}
            path={"/development"}
            exact
            component={Loadable({
              loader: () => import("views/development"),
              loading: TopBarProgress,
            })}
          />
          <Route
            key={"default"}
            exact
            path={"/"}
            component={Loadable({
              loader: () => import("views/dashboard"),
              loading: TopBarProgress,
            })}
          />
          <TutorialModal
            isModalOpen={isTutorialModalOpen}
            toggleIsTourOpen={toggleIsTourOpen}
            toggleModal={toggleTutorialModal}
          />
          {isTourOpen ? (
            <Tour
              onRequestClose={() => {
                toggleIsTourOpen(false);
                localStorage.setItem("IntroApp_Disabled", true);
              }}
              steps={tourConfig}
              isOpen={isTourOpen}
              maskClassName="tutorial-mask"
              className="helper"
              rounded={5}
              accentColor={"rgba(82, 177, 102, 1)"}
              onAfterOpen={disableBody}
              onBeforeClose={enableBody}
              lastStepNextButton={
                <Button
                  type="primary"
                  shape="circle"
                  icon={<CheckCircleOutlined />}
                />
              }
            />
          ) : null}
          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    </MainLayout>
  );
};

export default App;
