import styled from "styled-components";
import { Button, Layout } from "antd";

const { Header } = Layout;

const NavbarContainer = styled(Header)`
  background: #ffffff;
  padding-right: 10px !important;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 6em;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 5px;
`;

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 15px;
`;

const ProfileButton = styled(Button)`
  height: 100%;
  display: flex;
  align-items: center;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export {
  NavbarContainer,
  LogoContainer,
  ActionsContainer,
  BalanceContainer,
  NameContainer,
  ProfileButton,
  Row,
};
