import { gql } from "@apollo/client";

const LOCATION_FRAGMENT = gql`
  fragment LocationFragment on Location {
    id
    name
    phones {
      countryCode
      number
    }
    address {
      fullName
      country
      state
      city
      street
      postcode
      colony
      location {
        type
        coordinates
      }
    }
    reference
  }
`;

const GET_LOCATION = gql`
  ${LOCATION_FRAGMENT}
  query location($id: ID!) {
    location(id: $id) {
      ...LocationFragment
    }
  }
`;

const GET_LOCATIONS = gql`
  ${LOCATION_FRAGMENT}
  query locations($params: QueryParams, $search: LocationSearchInput) {
    locations(params: $params, search: $search) {
      info {
        count
        pages
        prev
        next
      }
      results {
        ...LocationFragment
      }
    }
  }
`;

const DELETE = gql`
  mutation delete($model: Models!, $id: ID!) {
    delete(model: $model, id: $id)
  }
`;

const CREATE_LOCATION = gql`
  mutation createLocation($location: LocationCreate!) {
    createLocation(location: $location) {
      id
    }
  }
`;

const UPDATE_LOCATION = gql`
  mutation updateLocation($id: ID!, $location: LocationUpdate!) {
    updateLocation(id: $id, location: $location) {
      id
    }
  }
`;

const VALIDATE_COORDINATES = gql`
  query validCoordinates($point: GeoJSONInput!) {
    validCoordinates(point: $point) {
      id
    }
  }
`;

const GET_GEOLOCATIONS = gql`
  {
    recollectionAreas {
      results {
        region {
          name
        }
        name
        extended
        areas {
          coordinates
        }
      }
    }
  }
`;

export {
  GET_LOCATION,
  GET_LOCATIONS,
  DELETE,
  CREATE_LOCATION,
  UPDATE_LOCATION,
  VALIDATE_COORDINATES,
  GET_GEOLOCATIONS,
};
