import React, { useMemo } from "react";
import { Avatar, Button, Dropdown, Menu, Typography, Tooltip } from "antd";
import { Link, useHistory } from "react-router-dom";
import shortid from "shortid";
import {
  DownOutlined,
  LogoutOutlined,
  SettingOutlined,
  DesktopOutlined,
  UserOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import {
  ActionsContainer,
  BalanceContainer,
  NameContainer,
  NavbarContainer,
  ProfileButton,
} from "./elements";
import { useUser } from "providers/user";
import { formatCurrency, openWhatsapp } from "utils/functions";
import useQueryRt from "hooks/requests/use-query-rt";
import {
  GET_LOCATION,
  GET_LOCATIONS,
} from "views/locations/locations-list/requests";
import { mongoModels, dropinContact } from "utils/constants";

const { Text } = Typography;

const NavBar = () => {
  const { push } = useHistory();
  const { user, logout } = useUser();

  const { data } = useQueryRt(
    { model: mongoModels.Location },
    { mainQuery: GET_LOCATIONS, populateQuery: GET_LOCATION }
  );

  const disableDeposits = useMemo(() => {
    return !user.permission.views.includes("deposits");
  }, [user]);

  return (
    <NavbarContainer data-tut="navbar">
      <ActionsContainer>
        <BalanceContainer>
          <Text style={{ fontSize: 12 }} type="secondary">
            Saldo:
          </Text>
          <Text
            style={{ marginTop: -44, fontSize: 18, color: "#52B266" }}
            strong
          >
            {`${formatCurrency(
              user?.business?.balance
            )} ${user?.business?.balance.currency.toUpperCase()}`}
          </Text>
        </BalanceContainer>

        <Button
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: 10,
            borderRadius: 50,
          }}
          ghost
          size="medium"
          type="primary"
          disabled={disableDeposits}
        >
          <Link to="/deposits">Recarga</Link>
        </Button>

        <Link
          to={
            user?.business?.balance.amount /
              Math.pow(10, user?.business?.balance.exponent) >
              50.0 && data?.locations?.results.length
              ? {
                  pathname: "/services",
                  search: "?new=true",
                }
              : {
                  pathname: "/services",
                }
          }
        >
          <Tooltip
            title={
              user?.business?.balance.amount /
                Math.pow(10, user?.business?.balance.exponent) <
              50.0
                ? "Agregar fondos para enviar un paquete."
                : !data?.locations?.results.length
                ? "Favor de registrar una bodega"
                : undefined
            }
          >
            <Button
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                borderRadius: 50,
                marginRight: 10,
              }}
              size="medium"
              type="primary"
              data-tut="create_service_btn"
            >
              Envíar Local
            </Button>
          </Tooltip>
        </Link>
        <Link
          to={{
            pathname: "/dashboard",
            search: "?new=true",
          }}
        >
          <Button
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              borderRadius: 50,
            }}
            size="medium"
            type="primary"
            data-tut="create_service_btn"
          >
            Envíar Nacional
          </Button>
        </Link>
      </ActionsContainer>
      <div>
        {/* <Button
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            borderRadius: 50,
          }}
          size="medium"
          type="primary"
        >
          <Link to="/quoter">Cotizar</Link>
        </Button>
        <Button
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: 10,
            borderRadius: 50,
          }}
          ghost
          size="medium"
          type="primary"
        >
          <Link to="/information">Promesa</Link>
        </Button> */}
        <Button
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: 10,
            borderRadius: 50,
          }}
          size="medium"
          icon={<WhatsAppOutlined />}
          type="primary"
          onClick={() =>
            openWhatsapp(dropinContact, "Hola, Necesito asistencia.")
          }
        >
          Soporte
        </Button>

        {/* <Button
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: 10,
            borderRadius: 50,
          }}
          ghost
          size="medium"
          type="primary"
        >
          <Link to="/">Precios</Link>
        </Button> */}
      </div>
      <Dropdown
        trigger={["click"]}
        overlay={
          <Menu mode="vertical">
            <Menu.ItemGroup title="Cuenta">
              <Menu.Item
                key={shortid.generate()}
                onClick={() => push("/profile")}
                icon={<UserOutlined />}
              >
                Perfil
              </Menu.Item>
              <Menu.Item
                key={shortid.generate()}
                onClick={() => push("/settings")}
                icon={<SettingOutlined />}
              >
                Configuración
              </Menu.Item>
              <Menu.Item
                key={shortid.generate()}
                onClick={() => push("/development")}
                icon={<DesktopOutlined />}
              >
                Desarrollo
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup title="Sesión">
              <Menu.Item
                key={shortid.generate()}
                onClick={() => logout()}
                icon={<LogoutOutlined />}
              >
                Salir
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        }
      >
        <ProfileButton type="text">
          <Avatar style={{ marginRight: 15 }} size={35} src={user?.profileImg}>
            {user?.firstName?.[0]}
          </Avatar>
          <NameContainer>
            <Text style={{ fontSize: 12 }} type="secondary">
              Hola,
            </Text>
            <Text style={{ marginTop: -5 }} strong>
              {user?.firstName}
            </Text>
          </NameContainer>
          <DownOutlined />
        </ProfileButton>
      </Dropdown>
    </NavbarContainer>
  );
};

export default React.memo(NavBar);
