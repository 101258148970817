import dotenv from "dotenv";
import urljoin from "url-join";

dotenv.config();

const env = {
  development: process.env.NODE_ENV === "development",
  production: process.env.NODE_ENV === "production",
};

const backend = {
  AUTH_URI: process.env.REACT_APP_SERVER_URI,
  HTTP_URI: urljoin(process.env.REACT_APP_SERVER_URI ?? "", "graphql"),
  WS_URI: urljoin(process.env.REACT_APP_WS_URI ?? "", "graphql"),
};

const google = {
  mapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
};

const conektaConfig = {
  publicApiKey: process.env.REACT_APP_CONEKTA_PUBLIC_API_KEY,
};

const publicNamespace = process.env.REACT_APP_PUBLIC_NAMESPACE;

const copomex = {
  key: process.env.REACT_APP_COPOMEX_KEY,
};

const here = {
  key: process.env.REACT_APP_HERE_KEY,
};

export { env, backend, google, conektaConfig, publicNamespace, copomex, here };
